import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const Hits = ({ hits }) => {

  return (
    <HitsUI>
      {hits.length > 0 ? (
        hits.map(hit => (
          <div
            css={`
              text-decoration: none;
            `}
            key={hit.objectID}
          >
            <Link
              css={`
                text-decoration: none;
              `}
              to={`/product/${hit["productTradeName"]
                .replace(/ /g, "+")
                .toLowerCase()}`}
            >
              <p>
                {hit["productTradeName"]
                  .toLowerCase()
                  .replace(/percent/, "%")}
              </p>
            </Link>
            <Link
              css={`
                text-decoration: none;
              `}
              to={`/search/${hit["innOfApi"]
                .replace(/ /g, "+")
                .toLowerCase()}?query=${hit["innOfApi"]
                .replace(/ /g, "+")
                .toLowerCase()}`}
            >
              <p>
                Brands containing{" "}
                {hit["innOfApi"].toLowerCase().replace(/percent/, "%")}{" "}
              </p>
            </Link>
            {/* <Link css={`text-decoration: none;`} to={`/search/${encodeURI(hit["Inn Of Api"]).toLowerCase().replace(/%20/g, "+")}?query=${encodeURI(hit["Inn Of Api"]).toLowerCase().replace(/%20/g, "+")}`}><p>{hit["Inn Of Api"].toLowerCase()} Containing Products</p></Link> */}
          </div>
        ))
      ) : (
        <div>No results found</div>
      )}
    </HitsUI>
  )
}

const HitsUI = styled.div`
  background: #fff;
  width: 17rem;
  height: 10rem;
  list-style: none;
  overflow: scroll;
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  position: absolute;

  @media screen and (min-width: 400px) {
    width: 20rem;
  }

  @media screen and (min-width: 600px) {
    width: 30rem;
  }

  @media screen and (min-width: 800px) {
    width: 40rem;
  }
`
