import React, { useState, useEffect } from "react"
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap"
import { Link } from "gatsby"
import pic37 from "../images/canva/38.png"
import odpc from "../images/canva/odpc.png"
import api from "../utils/api"
import LoadingOverlay from "react-loading-overlay"
import CookieConsent from "react-cookie-consent"
import { GoLocation } from 'react-icons/go';


const Footer = () => {
  const [newsletterEmail, setNewsletterEmail] = useState("")
  const [optInToPromotions, setOptInToPromotions] = useState(false)
  const [emailValid, setEmailValid] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
    if (!newsletterEmail || !/\S+@\S+\.\S+/.test(newsletterEmail)) {
      setEmailValid(false)
    } else {
      setEmailValid(true)
    }
  }, [newsletterEmail])

  const subscribe = async () => {
    try {
      setLoading(true)
      setError("")
      setSuccess("")
      await api().post(`/newsletter_registrations/register`, {
        email: newsletterEmail,
        promotions: optInToPromotions,
      })
      setSuccess("Successfully subscribed")
      setLoading(false)
      setTimeout(() => {
        setNewsletterEmail("")
        setError("")
        setSuccess("")
      }, 3000)
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <Container
      fluid
      style={{
        minHeight: "100%",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Row
        style={{
          minHeight: "100v",
          paddingTop: "50px",
          margin: "0px 20px",
        }}
      >
        <Row>
          <h1>Let's work together.</h1>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Image
              src={pic37}
              fluid
              style={{ width: "auto", height: "auto" }}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12} sm={6} >
                <div style={{ paddingBottom: "10px" }}>
                  {/* <Row>
                    <h6 >Approved by The Pharmacy and Poisons Board</h6>
                  </Row> */}
                  <Row>
                    <h5 style={{ color: "grey" }}>Mailing Address</h5>
                  </Row>
                  <Row>
                    <p>60653-00200 Nairobi</p>
                  </Row>
                  <Row>
                    <p><GoLocation />Reality Building Kiambu Road Nairobi</p>
                  </Row>
                </div>

                <div style={{ paddingBottom: "10px" }}>
                  <Row>
                    <h5 style={{ color: "grey" }}>Phone Number</h5>
                  </Row>
                  <Row>
                    <p>+254 792 640 973</p>
                  </Row>
                  {/* <Row>
                    <p>+254 726 115 048</p>
                  </Row> */}
                </div>

                <div style={{ paddingBottom: "10px" }}>
                  <Row>
                    <h5 style={{ color: "grey" }}>Email Address</h5>
                  </Row>
                  <Row>
                    <p>support@patameds.com</p>
                  </Row>

                </div>

                <div>
                  <Row>
                    <h5 style={{ color: "grey" }}>Visit our website</h5>
                  </Row>
                  <Row>
                    <a href="https://www.tripleaimsoftware.com/">
                      www.tripleaimsoftware.com
                    </a>
                  </Row>
                  <Row>
                    <a href="https://www.afyabook.com/" style={{ color: "orange", marginTop: "15px", fontSize: "1rem" }}>
                      Consumer/Patient website
                    </a>
                  </Row>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div>
                  <Row>
                    <h5 style={{ color: "grey" }}>Links</h5>
                  </Row>
                  <Row>
                    <Link to="/" style={{ color: "white" }}>
                      Home
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/terms_of_use" style={{ color: "white" }}>
                      Terms and Condition
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/privacy_policy" style={{ color: "white" }}>
                      Privacy and Policy
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/agreement" style={{ color: "white" }}>
                      Vendor Agreement
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/healthcare_workers" style={{ color: "white" }}>
                      For Healthcare Providers
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/retail_hospitals" style={{ color: "white" }}>
                      For Retailers/Hospitals
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/wholesaler" style={{ color: "white" }}>
                      For Wholesalers
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/distributor" style={{ color: "white" }}>
                      For Distributors
                    </Link>
                  </Row>

                  <Row>
                    <Form style={{ margin: "20px 0px" }}>
                      <Form.Label>
                        <h3>Subscribe for newsletters</h3>
                        <p style={{ color: "red" }}>{error}</p>
                        <p style={{ color: "green" }}>{success}</p>
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        placeholder="email address"
                        value={newsletterEmail}
                        onChange={e => setNewsletterEmail(e.target.value)}
                      />

                      <Form.Check
                        size="lg"
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        type="checkbox"
                        label="opt in to mail promotions"
                        checked={optInToPromotions}
                        value={optInToPromotions}
                        onChange={e => setOptInToPromotions(!optInToPromotions)}
                      />
                      <LoadingOverlay active={loading} spinner>
                        <Button
                          size="lg"
                          disabled={!emailValid}
                          onClick={subscribe}
                        >
                          Subscribe
                        </Button>
                      </LoadingOverlay>
                    </Form>
                  </Row>
                  <img src={odpc} alt="Data Protection" style={{width:'100px'}}/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
      <CookieConsent
        overlay="true"
        overlayStyle={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: "9999",
          backgroundColor: "#9e9e9eab",
        }}
      >
        This site uses cookies.
      </CookieConsent>
      <div
        css={`
          text-align: center;
          font-size: 0.5rem;
        `}
      >
        Copyright &copy; 2024 Tripleaim software. All rights reserved.
      </div>
    </Container>
  )
}

export default Footer
