import React from "react"
//import { updateQuery } from '../components/SearchBox'

const ClearSearch = ({ refine, items }) => {
  const updateQuery = (query, filter) =>
    window.history.replaceState(
      null,
      null,
      filter && filter.length
        ? `?query=${encodeURIComponent(query)}&filter=${encodeURIComponent(
            filter
          ).replace(/%20/g, "+")}`
        : query
        ? `?query=${encodeURIComponent(query)}`
        : "?"
    )
  const resetSearch = (refine, items) => {
    refine(items)
    updateQuery("")
  }
  return (
    // <button onClick={() => resetSearch(refine, items)} type="button" title="Reset Search">
    <span
      onClick={() => resetSearch(refine, items)}
      title="Reset Search"
      css={`
        font-size: 1.5rem;
        cursor: pointer;
      `}
      role="img"
      aria-label="Reset"
    >
      🔄
    </span>
    // </button>
  )
}

export default ClearSearch
