import * as React from "react"
import { useEffect, useState } from "react";
import api from "../utils/api"
import styled from "styled-components"
import NoResultsModal from "./NoResultsModal"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"


const SearchElement = () => {
  const isBrowser = typeof window !== "undefined"
  const isLoggedIn = isBrowser ? !!window.localStorage.getItem("user") : {}

  const [user] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || {} : {}
  )

  const [products, setProducts] = useState([])
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedSearch = useDebounce(search, 500);


  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      setProducts([])

      const result = await api().get(`/drugs?search=${search}`)

      //console.log("data", result.data)

      const productsData = result.data;

      setProducts(productsData);
      setLoading(false)
    }

    if (debouncedSearch) fetchData();
  }, [debouncedSearch]);



  const [isNoResultsModalOpen, setNoResultsModal] = useState(false)

  const toggleNoResultsModal = () => {
    setNoResultsModal(!isNoResultsModalOpen)
  }

  return (
    <Search >
      <NoResultsModal
        isModalOpen={isNoResultsModalOpen}
        closeModal={toggleNoResultsModal}
        user={user}
      />
      <input
        type="search"
        placeholder="search medication"
        onChange={(e) => setSearch(e.target.value)}
      />

      {search &&
        <Results>
          {loading && <p>Loading...</p>}
          {products.length > 0 ? products.map((product) => {
            return (<>
              <div key={product._id} >
                <Link to={`/product/${product["productTradeName"]
                  .replace(/ /g, "+")
                  .toLowerCase()}`}>
                  <p>{product.productTradeName.charAt(0).toUpperCase() + product.productTradeName.slice(1).toLowerCase()} - ({product.innOfApi.charAt(0).toUpperCase() + product.innOfApi.slice(1).toLowerCase()})</p>
                </Link>
              </div>
            </>)
          }) : <>
            {!loading && (
              <div>No results found</div>
            )}
            {isLoggedIn && !loading && (
              <Button onClick={() => toggleNoResultsModal()}>
                Find it for me
              </Button>
            )}</>
          }
        </Results>
      }
    </Search>

  )
}

export default SearchElement

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 

  input {
    padding: 1rem 1rem;
    outline: none;
    width: 17rem;
    height: 40px;
    border-radius: 10px;
    border: 2px solid #71c4de;

    @media screen and (min-width: 400px) {
      width: 20rem;
    }

    @media screen and (min-width: 600px) {
      width: 30rem;
    }

    @media screen and (min-width: 800px) {
      width: 40rem;
    }
  }
`

const Results = styled.div`
  overflow: auto;
  position: absolute;
  top: 7rem;
  background: white;
  
    padding: 1rem 1rem;
    outline: none;
    width: 17rem;
    height: 300px;
    border-radius: 10px;
    border: 2px solid #71c4de;

    @media screen and (min-width: 400px) {
      width: 20rem;
    }

    @media screen and (min-width: 600px) {
      width: 30rem;
    }

    @media screen and (min-width: 800px) {
      width: 40rem;
      height: 400px;
    }
  
`
