import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { Logout } from "../utils/authentication"
import logo from "../images/logo.svg"
import { Link, navigate } from "gatsby"
import { Button, Dropdown, Alert, Row, Container, Col, Form } from "react-bootstrap"
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
import { useLocation } from "@reach/router"
import api from "../utils/api"
import { NavbarContext } from "../context/navbar"
import LoadingOverlay from "react-loading-overlay"
import algoliasearch from "algoliasearch/lite"
import { Hits } from "../components/DisplayHits"
import SearchElement from "./searchElement"
import { BsShareFill } from "react-icons/bs"
import { CgProfile } from "react-icons/cg"
import { GrTransaction } from "react-icons/gr"
import { AiOutlineShoppingCart } from "react-icons/ai"
import {
  connectHits,
  InstantSearch,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom"
import SearchBox from "../components/SearchBox"
import { useQueryParam, StringParam } from "use-query-params"
import { globalHistory } from "@reach/router"
import ProgressiveProfillingForm from "../components/home/ProgressiveProfillingForm"
import NoResultsModal from "./NoResultsModal"
import { FaShoppingCart } from 'react-icons/fa';
import { FaBook } from 'react-icons/fa';


function VerificationAlert({
  email,
  isMobile,
  isOpenVerifyLoginAlert,
  setIsOpenVerifyLogin,
}) {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(true)

  const sendEmailVerificationLink = async e => {
    try {
      e.preventDefault()
      setIsLoading(true)
      setSuccess("")
      setError("")
      await api().post(`/users/request_email_verification_link`, {
        email,
      })
      setSuccess(`Sent email verification link to ${email}`)
      setIsLoading(false)
    } catch (error) {
      const message = error.response.data.message
      setIsLoading(false)
      setError(message)
    }
  }
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      style={{ width: "100vw", height: "auto", marginBottom: "0px" }}
    >
      <Alert
        show={isOpenVerifyLoginAlert}
        variant={success ? "success" : "danger"}
        style={{
          width: "100vw",
          marginBottom: "0px",
        }}
      >
        <Alert.Heading as={success ? "h6" : "h5"}>
          {isMobile && (
            <div className="d-flex justify-content-between">
              {success ? "Sent verification link" : "Verify your email."}

              <Button
                size="sm"
                style={{ width: "min-content" }}
                variant="secondary"
                onClick={e => setIsOpenVerifyLogin(false)}
              >
                Close
              </Button>
            </div>
          )}

          {!isMobile && (
            <div className="d-flex justify-content-between">
              <Button
                size="sm"
                style={{
                  width: "min-content",
                  visibility: "hidden",
                }}
                variant="secondary"
              >
                Close
              </Button>
              {success ? "Sent verification link" : "Verify your email."}

              <Button
                size="sm"
                style={{ width: "min-content" }}
                variant="secondary"
                onClick={e => setIsOpenVerifyLogin(false)}
              >
                Close
              </Button>
            </div>
          )}
        </Alert.Heading>

        <p style={{ textAlign: "center", margin: "0px" }}>
          Resend email verification link to{" "}
          <Alert.Link onClick={e => sendEmailVerificationLink(e)}>
            {email}
          </Alert.Link>
        </p>
      </Alert>
    </LoadingOverlay>
  )
}

const Header = ({
  navbar,
  toggle,
  scroll,
  aboutRef,
  topRef,
  servicesRef,
  footerRef,
  tutorialRef,
  profileRef,
  resourcesRef,
  blogRef,
  isOpenVerifyLoginAlert,
  setIsOpenVerifyLogin,
}) => {
  const location = useLocation()
  const page = location.pathname
  const isBrowser = typeof window !== "undefined"

  const [user] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || {} : {}
  )
  const isLoggedIn = isBrowser ? !!window.localStorage.getItem("user") : {}

  const [isMobile, setIsMobile] = useState(
    isBrowser && window.innerWidth <= 768 ? true : false
  )

  const mediaQuery = "(max-width: 768px)"

  if (isBrowser) {
    let mediaQueryList = window.matchMedia(mediaQuery)
    mediaQueryList.addEventListener("change", event => {
      if (event.matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const goTo = path => {
    navigate(path)
  }

  /*  const aboutRef = React.useRef(null)
  */

  const navContext = useContext(NavbarContext)
  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navContext.setUser(null)
    navigate("/")
  }

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_ADMIN_KEY
  )

  const CustomSearchBox = connectSearchBox(SearchBox)
  const CustomHits = connectHits(Hits)

  let urlQuery = ""

  const currentPage = globalHistory.location.pathname

  if (currentPage.includes("search")) {
    urlQuery = globalHistory.location.search
      ? globalHistory.location.search.replace("?query=", "")
      : ""
  }

  if (currentPage.includes("product")) {
    urlQuery = globalHistory.location.search
      ? globalHistory.location.search.replace("?query=", "")
      : globalHistory.location.pathname
        .split("/")
        .slice(2)
        .toString()
        .replace(/\+/g, " ")
  }

  const [cartCount, setCartCount] = useState(0);

useEffect(() => {
  const updateCartCount = () => {
    const count = localStorage.getItem('cartCount');
    if (count) {
      setCartCount(parseInt(count, 10));
    } else {
      setCartCount(0); 
    }
  };

  updateCartCount();

  window.addEventListener('storage', updateCartCount);
  window.addEventListener('cartUpdated', updateCartCount);

  return () => {
    window.removeEventListener('storage', updateCartCount);
    window.removeEventListener('cartUpdated', updateCartCount);
  };
}, []);


  const cartCountStyle = {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    background: 'red',
    color: 'white',
    borderRadius: '50%',
    padding: '2px 5px', 
    fontSize: '12px', 
    lineHeight: '10px', 
    minWidth: '18px', 
    textAlign: 'center',
  };

  const cartIconWrapperStyle = {
    position: 'relative',
    display: 'inline-block',
  };

  const [token] = useQueryParam("token", StringParam)

  if (token) {
    urlQuery = ""
  }

  urlQuery = urlQuery.replace("+", " ")

  const [isNoResultsModalOpen, setNoResultsModal] = useState(false)

  const toggleNoResultsModal = () => {
    setNoResultsModal(!isNoResultsModalOpen)
  }

  const Results = connectStateResults(({ searchState, searchResults }) =>
    searchState && searchState.query ? (
      <div>
        {searchResults.nbHits > 0 ? (
          <CustomHits />
        ) : (
          <div style={{ padding: "10px", textAlign: "center" }}>
            <p>No results found</p>
            {isLoggedIn && (
              <Button onClick={() => toggleNoResultsModal()}>
                Find it for me
              </Button>
            )}
          </div>
        )}
      </div>
    ) : (
      <div></div>
    )
  )

  return (<>
    <div
    // style={{
    //   paddingBottom: `${isOpenVerifyLoginAlert ? (isMobile ? "230px" : "220px") : "120px"
    //     }`,
    // }}
    >
      <ProgressiveProfillingForm />
      <NoResultsModal
        isModalOpen={isNoResultsModalOpen}
        closeModal={toggleNoResultsModal}
        user={user}
      />
      <Nav2>
        {/* {isOpenVerifyLoginAlert && (
          <VerificationAlert
            isOpenVerifyLoginAlert={isOpenVerifyLoginAlert}
            setIsOpenVerifyLogin={setIsOpenVerifyLogin}
            isMobile={isMobile}
            email={user.email}
          />
        )} */}
        <Row>
          <Nav3>
            <LinkLogo to="/">
              <img src={logo} alt="logo" />
            </LinkLogo>
            {!isMobile && page == "/" && (
              <div style={{ display: "flex" }}>
                <h6
                  onClick={() => {
                    scroll(aboutRef)
                  }}
                  style={{ margin: "0px 10px", cursor: "pointer" }}
                >
                  About
                </h6>
                <h6
                  onClick={() => {
                    scroll(resourcesRef)
                  }}
                  style={{ margin: "0px 10px", cursor: "pointer" }}
                >
                  CME
                </h6>
                <h6
                  onClick={() => {
                    scroll(servicesRef)
                  }}
                  style={{ margin: "0px 10px", cursor: "pointer" }}
                >
                  Services
                </h6>
                <h6
                  onClick={() => {
                    scroll(blogRef)
                  }}
                  style={{ margin: "0px 10px", cursor: "pointer" }}
                >
                  Blog
                </h6>
                <h6
                  onClick={() => {
                    scroll(footerRef)
                  }}
                  style={{ margin: "0px 10px", cursor: "pointer" }}
                >
                  Contact
                </h6>
              </div>
            )}
            {/* {!isMobile && page !== "/" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InstantSearch
                  searchClient={searchClient}
                  indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
                >
                  <SearchHit>
                    <CustomSearchBox defaultRefinement={urlQuery} />
                    <Results />
                  </SearchHit>
                </InstantSearch>
              </div>
            )} */}
            {isLoggedIn === true ? (
              <Navdiv>
                <StyledLink href="/cart">
                  <div style={cartIconWrapperStyle}>
                    <StyledFaShoppingCart />
                    {cartCount > 0 && (
                      <span style={cartCountStyle}>{cartCount}</span>
                    )}
                  </div>
                </StyledLink>
                <Dropdown>
                  <Dropdown.Toggle variant="success">
                    {user?.firstName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        goTo("/profile")
                      }}
                    >
                      <CgProfile style={{ display: "inline", marginRight: "5px" }} />Your Profile
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      as="button"
                      onClick={() => {
                        goTo("/catalogue")
                      }}
                    >
                      <FaBook style={{ display: "inline", marginRight: "5px" }} />Catalogue
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        goTo("/orders")
                      }}
                    >
                      <GrTransaction style={{ display: "inline", marginRight: "5px" }} />Your Orders
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      as="button"
                      onClick={() => {
                        goTo("/checkoutorders")
                      }}
                    >
                      <AiOutlineShoppingCart style={{ display: "inline", marginRight: "5px" }} />Checkout Orders
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        alert("Link Copied to Clipboard!");
                      }}
                    >
                      <BsShareFill style={{ display: "inline", marginRight: "5px" }} />Share Link
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      style={{ color: "red" }}
                      as="button"
                      onClick={e => {
                        logout()
                      }}
                    >
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {isMobile == true && page == "/" && (
                  <div>
                    {navbar == true ? (
                      <Button style={{ minWidth: "0px" }} onClick={toggle}>
                        <MdClose />
                      </Button>
                    ) : (
                      <Button style={{ minWidth: "0px" }} onClick={toggle}>
                        <FiMenu />
                      </Button>
                    )}
                  </div>
                )}
              </Navdiv>
            ) : (
              <Navdiv>
                <div
                  css={`
                    cursor: pointer;
                  `}
                  onClick={e => {
                    navigate("/login")
                    e.preventDefault()
                  }}
                >
                  Log In
                </div>
              </Navdiv>
            )}
          </Nav3>
        </Row>
        {/* {(isMobile || page == "/") && (
          <Row>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InstantSearch
                searchClient={searchClient}
                indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
              >
                <SearchHit>
                  <CustomSearchBox defaultRefinement={urlQuery} />
                  <Results />
                </SearchHit>
              </InstantSearch>
            </div>
          </Row>
        )} */}
        <SearchElement />

      </Nav2>
    </div>
  </>
  )
}

export default Header

const Nav2 = styled.nav`
  background: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  
`

const Nav3 = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
`

const Nav4 = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  width: 10rem;
`

const Div2 = styled.div`
  background: #afd7e9;
  padding: 1px;
  border-radius: 1rem;
  cursor: pointer;
`
const LinkLogo = styled(Link)`
  width: 35%;

  @media screen and (min-width: 600px) {
    width: 20%;
  }
  @media screen and (min-width: 800px) {
    width: 10%;
  }
  @media screen and (min-width: 1100px) {
    width: 7.5%;
  }
  @media screen and (min-width: 1500px) {
    width: 7%;
  }
`
const Navdiv = styled.div`
  z-index: 1001;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (min-width: 600px) {
    width: 200px;
  }
`
const StyledFaShoppingCart = styled(FaShoppingCart)`
  color: #89C0DA; 
  font-size: 24px; 
`
const StyledLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none; 
  
  &:hover {
    color: #afd7e9;
  }
`

const SearchHit = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: sticky;
  //top: 60px;

  @media screen and (min-width: 1200px) {
    //top: 15px;
  }
`
