import React, { useState } from "react"
import { Row, Form, Button, Alert } from "react-bootstrap"
import Modal from "react-modal"
import api from "../utils/api"

const modalStyles = {
  content: {
    top: "25%",
    bottom: "auto",
    margin: "auto",
    maxWidth: "300px",
    minWidth: "min-content",
  },
  overlay: {
    zIndex: "5000",
    backgroundColor: "#212529c2",
  },
}

export default function NoResultsModal({ isModalOpen, closeModal, user }) {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [productName, setProductName] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  function close() {
    setError("")
    setLoading(false)
    setPhoneNumber("")
    setProductName("")
    closeModal()
  }

  const submit = async () => {
    setLoading(true)
    setError("")
    if (!phoneNumber) {
      setLoading(false)
      return setError("Phone number is required")
    }
    if (!productName) {
      setLoading(false)
      return setError("Product name is required")
    }

    try {
      await api().post(`/searches`, {
        userId: user.id,
        phoneNumber,
        productName,
      })
      setLoading(false)
      close()
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={close}
      style={modalStyles}
      ariaHideApp={false}
    >
      <Row
        style={{
          backgroundColor: "white",
          color: "black",
        }}
      >
        <div style={{ margin: "auto" }}>
          <Form
            style={{
              margin: "0px",
              padding: "20px",
              backgroundColor: "#03989e",
            }}
          >
            {error ? <Alert variant="danger">{error}</Alert> : ""}
            <h2>Find it for me</h2>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                value={productName}
                onChange={e => setProductName(e.target.value)}
              />
            </Form.Group>

            <Button
              disabled={loading}
              onClick={() => {
                submit()
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Row>
    </Modal>
  )
}
