import React from "react"
import styled from "styled-components"
import { connectCurrentRefinements } from "react-instantsearch-dom"
import ClearSearch from "./../utils/clearSearch"

export default function SearchBox({
  currentRefinement,
  onFocus,
  isSearchStalled,
  refine,
  props,
}) {
  const handleFocus = event => event.target.select()

  const ClearAllButton = connectCurrentRefinements(ClearSearch)

  return (
    <form noValidate action="" role="search" onSubmit={e => e.preventDefault()}>
      <Search>
        <input
          type="search"
          value={currentRefinement.replace(/percent/, "%")}
          onChange={event => refine(event.currentTarget.value)}
          placeholder="Search by brand or generic name"
          aria-label="Search"
          onFocus={onFocus}
          onClick={handleFocus}
        />
        <ClearAllButton clearsQuery />
      </Search>
      {isSearchStalled
        ? "Sorry, search is stalling, please wait a moment."
        : ""}
    </form>
  )
}

const Search = styled.div`
  display: flex;

  input {
    padding: 1rem 1, 5rem;
    outline: none;
    width: 17rem;
    height: 40px;
    border-radius: 10px;
    border: 2px solid #71c4de;

    @media screen and (min-width: 400px) {
      width: 20rem;
    }

    @media screen and (min-width: 600px) {
      width: 30rem;
    }

    @media screen and (min-width: 800px) {
      width: 40rem;
    }
  }
`
