import React, { useState, useRef, useEffect } from "react"
import Modal from "react-modal"
import { Form, Button, Alert, ListGroup } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import api from "../../utils/api"
import styled from "styled-components"
import LoadingOverlay from "react-loading-overlay"

const customStyles = {
  content: {
    top: "25%",
    bottom: "auto",
    margin: "auto",
    maxWidth: "300px",
  },
  overlay: {
    zIndex: "5000",
    backgroundColor: "#000000d4",
  },
}

function SelectPlaceOfWork({ setFormState, setPlaceOfWork }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [search, setSearch] = useState("")

  const [placesOfWork, setPlacesOfWork] = useState([])

  const [allPlacesOfWork, setAllPlacesOfWork] = useState([])

  const [filteredPlacesOfWork, setFilteredPlacesOfWork] = useState([])
  const [selectedPlaceOfWork, setSelectedPlaceOfWork] = useState("")

  const [formAction, setFormAction] = useState("enter")

  const trackingRef = useRef(false)

  useEffect(() => {
    const matches = placesOfWork.filter(place =>
      place.toLowerCase().includes(search.toLowerCase())
    )
    const limit = matches.splice(0, 5)

    trackingRef.current = true
    setFilteredPlacesOfWork(limit)
    if (search) {
      const options = filteredPlacesOfWork.filter(place =>
        place.toLowerCase().includes(search.toLowerCase())
      )

      if (options) {
        setSelectedPlaceOfWork(options[0])
      }
    } else {
      setSelectedPlaceOfWork("")
    }
  }, [search])

  const fetchPlacesOfWork = async () => {
    try {
      const result = await api().get(`/place_of_work`)
      const data = result.data

      const places = data
        .map(place => {
          return place.name
        })
        .sort()

      setPlacesOfWork(
        data
          .map(place => {
            return place.name
          })
          .sort()
      )
      setAllPlacesOfWork(data)
      setFilteredPlacesOfWork(
        data
          .map(place => {
            return place.name
          })
          .sort()
          .splice(0, 5)
      )
    } catch (error) {
      const message = error.response.data.message
      setError(message)
    }
  }

  useEffect(() => {
    fetchPlacesOfWork()
  }, [])

  // useEffect(() => {
  //   if (formAction == "enter") {
  //     setSelectedPlaceOfWork("")
  //   }
  // }, [formAction])

  const submitPlaceOfWork = () => {
    if (formAction == "select") {
      let place = allPlacesOfWork.find(
        place => place.name == selectedPlaceOfWork
      )
      if (!place.hasOwnProperty("isVerified")) {
        place.isVerified = false
      }
      setPlaceOfWork(place)
    } else {
      setPlaceOfWork({ name: selectedPlaceOfWork, isVerified: false })
    }
  }

  //console.log("place work forms", formAction, selectedPlaceOfWork)

  return (
    <div>
      {/* {formAction == "select" && (
        <Form>
          <p>Please enter your place of work:</p>

          <div>
            <Form.Control
              autoFocus
              className="my-2"
              type="text"
              placeholder="Search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>

          <div>
            {filteredPlacesOfWork.length > 0 ? (
              <ListGroup as="ul">
                {filteredPlacesOfWork.map(placeOfWork => (
                  <ListGroup.Item
                    active={selectedPlaceOfWork == placeOfWork}
                    as="li"
                    key={placeOfWork}
                    onClick={() => setSelectedPlaceOfWork(placeOfWork)}
                  >
                    {placeOfWork}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p>No places of work found with that search</p>
            )}
          </div>
        </Form>
      )} */}

      {formAction == "enter" && (
        <Form>
          <p>Please enter your place of work:</p>

          <Form.Control
            type="text"
            autoFocus
            placeholder="Enter place of work"
            value={selectedPlaceOfWork}
            onChange={e => setSelectedPlaceOfWork(e.target.value)}
          />
        </Form>
      )}

      <br />
      <LoadingOverlay active={loading} spinner text="">
        {/* {formAction == "select" && (
          <Button
            style={{
              marginTop: "10px",
              color: "white",
            }}
            variant="secondary"
            type="button"
            onClick={() => setFormAction("enter")}
          >
            Add +
          </Button>
        )} */}

        {/* {formAction == "enter" && (
          <Button
            style={{
              marginTop: "10px",
              color: "white",
            }}
            type="button"
            variant="secondary"
            onClick={() => setFormAction("select")}
          >
            Back
          </Button>
        )} */}

        <Button
          disabled={loading || !selectedPlaceOfWork}
          style={{
            marginTop: "10px",
            color: "white",
          }}
          variant="primary"
          type="button"
          onClick={submitPlaceOfWork}
        >
          Next
        </Button>
      </LoadingOverlay>
    </div>
  )
}

function SelectCountry({ setFormState, setCountryOfWork }) {
  const countries = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua And Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia And Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic",
    "Cook Islands",
    "Costa Rica",
    "Cote D'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island & Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Isle Of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States Of",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts And Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre And Miquelon",
    "Saint Vincent And Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome And Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia And Sandwich Isl.",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard And Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad And Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks And Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis And Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ]

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [search, setSearch] = useState("")

  const [filteredCountries, setFilteredCountries] = useState(countries.sort())
  const [selectedCountry, setSelectedCountry] = useState("")

  const trackingRef = useRef(false)

  useEffect(() => {
    const matches = countries.filter(country =>
      country.toLowerCase().includes(search.toLowerCase())
    )
    const limit = matches.splice(0, 5)

    trackingRef.current = true
    setFilteredCountries(limit)
    if (search) {
      const options = filteredCountries.filter(country =>
        country.toLowerCase().includes(search.toLowerCase())
      )

      if (options) {
        setSelectedCountry(options[0])
      }
    } else {
      setSelectedCountry("")
    }
  }, [search])

  const backStep = async () => {
    setFormState(1)
  }

  const submitCountryOfWork = () => {
    setCountryOfWork(selectedCountry)
    setFormState(3)
  }

  return (
    <div>
      <Form>
        <p>Please select your country of work:</p>

        <div>
          <Form.Control
            autoFocus
            className="my-2"
            type="text"
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>

        <div>
          {filteredCountries.length > 0 ? (
            <ListGroup as="ul">
              {filteredCountries.map(country => (
                <ListGroup.Item
                  active={selectedCountry == country}
                  as="li"
                  key={country}
                  onClick={() => { setSelectedCountry(country); setSearch(country) }}
                >
                  {country}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>No countries found with that search</p>
          )}
        </div>
      </Form>
      <br />
      <LoadingOverlay active={loading} spinner text="">
        <Button
          disabled={loading}
          style={{
            backgroundColor: "grey",
            color: "white",
          }}
          type="button"
          onClick={() => {
            backStep()
          }}
        >
          Back
        </Button>
        {selectedCountry === "Kenya" ? (
          <Button
            disabled={loading || !selectedCountry}
            style={{
              marginTop: "10px",
              color: "white",
            }}
            variant="primary"
            type="button"
            onClick={submitCountryOfWork}
          >
            Next
          </Button>
        ) : (
          <Button
            disabled={loading || !selectedCountry}
            style={{
              marginTop: "10px",
              color: "white",
            }}
            variant="primary"
            type="button"
            onClick={submitCountryOfWork}
          >
            Submit
          </Button>
        )}
      </LoadingOverlay>
    </div>
  )
}

function SelectCounty({ setFormState, setCountyOfWork }) {
  const counties = [
    "Mombasa",
    "Kwale",
    "Kilifi",
    "Tana River",
    "Lamu",
    "Taita/Taveta",
    "Garissa",
    "Wajir",
    "Mandera",
    "Marsabit",
    "Isiolo",
    "Meru",
    "Tharaka-Nithi",
    "Embu",
    "Kitui",
    "Machakos",
    "Makueni",
    "Nyandarua",
    "Nyeri",
    "Kirinyaga",
    "Murang'a",
    "Kiambu",
    "Turkana",
    "West Pokot",
    "Samburu",
    "Trans Nzoia",
    "Uasin Gishu",
    "Elgeyo/Marakwet",
    "Nandi",
    "Baringo",
    "Laikipia",
    "Nakuru",
    "Narok",
    "Kajiado",
    "Kericho",
    "Bomet",
    "Kakamega",
    "Vihiga",
    "Bungoma",
    "Busia",
    "Siaya",
    "Kisumu",
    "Homa Bay",
    "Migori",
    "Kisii",
    "Nyamira",
    "Nairobi",
  ]

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [search, setSearch] = useState("")

  const [filteredCounties, setFilteredCounties] = useState(counties.sort())
  const [selectedCounty, setSelectedCounty] = useState("")

  const trackingRef = useRef(false)

  useEffect(() => {
    const matches = counties.filter(county =>
      county.toLowerCase().includes(search.toLowerCase())
    )
    const limit = matches.splice(0, 5)

    trackingRef.current = true
    setFilteredCounties(limit)
    if (search) {
      const options = filteredCounties.filter(county =>
        county.toLowerCase().includes(search.toLowerCase())
      )

      if (options) {
        setSelectedCounty(options[0])
      }
    } else {
      setSelectedCounty("")
    }
  }, [search])

  const backStep = async () => {
    setFormState(2)
  }

  const submitCountyOfWork = () => {
    setCountyOfWork(selectedCounty)
    setFormState(4)
  }

  return (
    <div>
      <Form>
        <p>Please select your county of work:</p>

        <div>
          <Form.Control
            autoFocus
            className="my-2"
            type="text"
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>

        <div>
          {filteredCounties.length > 0 ? (
            <ListGroup as="ul">
              {filteredCounties.map(county => (
                <ListGroup.Item
                  active={selectedCounty == county}
                  as="li"
                  key={county}
                  onClick={() => { setSelectedCounty(county); setSearch(county) }}
                >
                  {county}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>No counties found with that search</p>
          )}
        </div>
      </Form>
      <br />
      <LoadingOverlay active={loading} spinner text="">
        <Button
          disabled={loading}
          style={{
            backgroundColor: "grey",
            color: "white",
          }}
          type="button"
          onClick={() => {
            backStep()
          }}
        >
          Back
        </Button>
        <Button
          disabled={loading || !selectedCounty}
          style={{
            backgroundColor: "#03989e",
            marginTop: "10px",
            color: "white",
          }}
          type="button"
          onClick={submitCountyOfWork}
        >
          Next
        </Button>
      </LoadingOverlay>
    </div>
  )
}

export default function ProgressiveProfillingForm({ open, close, tab }) {
  const isBrowser = typeof window !== "undefined"

  const [user] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || {} : {}
  )

  const loggedInUser = user

  const isLoggedIn = isBrowser ? !!window.localStorage.getItem("user") : {}

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [modalIsOpen, setIsOpen] = useState(open)
  const [countryOfWork, setCountryOfWork] = useState("")
  const [countyOfWork, setCountyOfWork] = useState("")
  const [placeOfWork, setPlaceOfWork] = useState([])
  const [formState, setFormState] = useState(1)
  const [search, setSearch] = useState("")

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const sendPlaceOfWork = async () => {
    setSuccess("")
    setError("")
    setLoading(true)

    try {
      if (!placeOfWork) {
        const message = "Place of work required"
        setLoading(false)
        return setError(message)
      } else if (!countryOfWork) {
        const message = "Country of work required"
        setLoading(false)
        return setError(message)
      } else if (countryOfWork === "Kenya" && !countyOfWork) {
        const message = "County of work required"
        setLoading(false)
        return setError(message)
      } else {
        const data = {
          ...loggedInUser,
          placeOfWork: {
            name: placeOfWork.name,
            countryOfWork: countryOfWork,
            countyLocation: countyOfWork,
            isVerified: placeOfWork.isVerified,
          },
        }
        const result = await api().patch(`/users/${loggedInUser.id}`, {
          ...data,
        })

        const user = result.data

        localStorage.removeItem("user")
        localStorage.setItem("user", JSON.stringify(user))
        setLoading(false)
        setFormState(4)
      }
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  React.useEffect(() => {
    setFormState(2)
  }, [placeOfWork])

  React.useEffect(() => {
    if (countryOfWork) {
      if (countryOfWork === "Kenya") {
        setFormState(3)
      } else {
        sendPlaceOfWork()
      }
    }
  }, [countryOfWork])

  React.useEffect(() => {
    if (countyOfWork) {
      sendPlaceOfWork()
    }
  }, [countyOfWork])

  React.useEffect(() => {
    if (
      isLoggedIn &&
      user.loggedInCount &&
      user.loggedInCount > 1 &&
      !user?.placeOfWork?.name
    ) {
      openModal()
      if (!user?.placeOfWork?.name || Object.keys(user?.placeOfWork).length === 0) {
        setFormState(1)
      }
    }
  }, [])

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <Container>
        {error ? <Alert variant="danger">{error}</Alert> : ""}
        {success ? <Alert variant="success">{success}</Alert> : ""}

        {formState == 1 && !user.placeOfWork?.name && (
          <SelectPlaceOfWork
            setFormState={setFormState}
            setPlaceOfWork={setPlaceOfWork}
          />
        )}

        {formState == 2 && !user.placeOfWork?.countryOfWork && (
          <SelectCountry
            setCountryOfWork={setCountryOfWork}
            setFormState={setFormState}
          />
        )}

        {formState == 3 && (
          <SelectCounty
            setCountyOfWork={setCountyOfWork}
            setFormState={setFormState}
          />
        )}

        {formState == 4 && (
          <div>
            <h3 className="text-success">Thank you for submitting</h3>
            <br />
            <Button
              variant="secondary"
              type="button"
              block
              onClick={closeModal}
            >
              Close
            </Button>
          </div>
        )}
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
