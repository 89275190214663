import { navigate } from "gatsby"
import api from "../utils/api"

const isBrowser = typeof window !== "undefined"

//let user = localStorage.getItem("user") || {}
let user = isBrowser ? window.localStorage.getItem("user") || {} : {}

//let token = localStorage.getItem("token") || null
let token = isBrowser ? window.localStorage.getItem("token") || null : null

const isLoggedIn = !!token

export const IsAuthenticated = () => {
  return isLoggedIn
}

export const IsLoggedIn = () => {
  return isLoggedIn
}

export const Login = async logins => {
  try {
    const result = await api().post(`/users/login`, { ...logins })

    localStorage.setItem("token", result.data.token)
    localStorage.setItem("user", JSON.stringify(result.data.user))
    user = localStorage.getItem("user")
    token = localStorage.getItem("token")
  } catch (error) {
    return error
  }
}

export const Register = async registration => {
  try {
    const result = await api().post(`/users/register`, { ...registration })

    localStorage.setItem("token", result.data.token)
    localStorage.setItem("user", JSON.stringify(result.data.user))
    user = localStorage.getItem("user")
    token = localStorage.getItem("token")
  } catch (error) {
    return error
  }
}

export const Logout = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("user")
  navigate("/")
  /* window.location.reload() */
}

export const IsProtectedRoute = () => {
  const protectedRoutes = [
    // `/home`,
    // `/callback`,
    `/category`,
    `/product`,
    `/search`,
  ]

  return protectedRoutes
    .map(route => window.location.pathname.includes(route))
    .some(route => route)
}

export const GetProfile = () => {
  return JSON.parse(user)
}
