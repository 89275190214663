import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
    * {
        //font-family: 'Montserrat', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    // h1, h2, h3, h4, h5, h6 {
    //     font-family: 'Playfair Display', sans-serif;
    // }
`
