import React, { useState, useEffect } from "react"
import Header from "./header"
import { GlobalStyle } from "./styles/GlobalStyles"
import { Link } from "gatsby"
import jwt_decode from "jwt-decode"
import { Logout } from "../utils/authentication"
import Footer from "./footer"

import { NavbarContext } from "../context/navbar"

const Layout = ({ children, urlQuery }) => {
  const isBrowser = typeof window !== "undefined"
  const [token] = useState(
    (isBrowser && window.localStorage.getItem("token")) || null
  )

  if (typeof window !== `undefined`) {
    window.dataLayer = window.dataLayer || []
  }

  useEffect(() => {
    if (token) {
      const decoded = jwt_decode(token)
      const expirationTime = decoded.exp * 1000 - 60000
      window.dataLayer.push({ event: 'user_data', userId: decoded.sub });
      //console.log(window.dataLayer)

      if (Date.now() >= expirationTime) {
        Logout()
      }
    }
  }, [])

  return (
    <NavbarContext.Consumer>
      {context => (
        <React.Fragment>
          <div
            css={`
              background: #e4e4e4;
              margin-top:7rem;
              border-top:5px solid #e4e4e4;
            `}
          >
            <GlobalStyle />

            <Header
              navbar={context?.navbar}
              toggle={context?.toggle}
              scroll={context?.scroll}
              aboutRef={context?.aboutRef}
              topRef={context?.topRef}
              servicesRef={context?.servicesRef}
              footerRef={context?.footerRef}
              tutorialRef={context?.tutorialRef}
              profileRef={context?.profileRef}
              resourcesRef={context?.resourcesRef}
              blogRef={context?.blogRef}
              isOpenVerifyLoginAlert={context?.isOpenVerifyLoginAlert}
              setIsOpenVerifyLogin={context?.setIsOpenVerifyLogin}
            />
            <main>{children}</main>
            <Footer />
          </div>
        </React.Fragment>
      )}
    </NavbarContext.Consumer>
  )
}

export default Layout

